<template>
	<div class="d-flex flex-column justify-content-between h-100">
		<div class="d-flex justify-content-between font-weight-bold small mb-2">
			<div>
				{{ usedStorage }}% Full
			</div>
			<div class="upgrade-link">
				<span>
					<slot />
				</span>
			</div>
		</div>
		<b-progress v-bind:class="{ warning: usedStorage >= 85 }" :value="usedStorage < 5 ? 5 : usedStorage" :max="max"></b-progress>
	</div>
</template>

<script>
	export default {
		name: 'UsedStorage',
		data () {
			return {
				usedStorageBytes: 0,
				max: 100
			};
		},
		computed: {
			StorageSize () {
				const { StorageSize } = this.$FeathersVuex.api;
				return StorageSize;
			},
			Styles () {
				const { Styles } = this.$FeathersVuex.api;
				return Styles;
			},
			usedStorage () {
				return Math.ceil((this.usedStorageBytes / 200000) * 100);
			}
		},
		methods: {
			getUsedStorage () {
				this.StorageSize.get(this.user._id).then(storageSize => {
					this.usedStorageBytes = storageSize.quotaUsage || 0;
				});
			}
		},
		mounted () {
			this.getUsedStorage();
			this.Styles.on('created', this.getUsedStorage);
			this.Styles.on('updated', this.getUsedStorage);
			this.Styles.on('patched', this.getUsedStorage);
			this.Styles.on('removed', this.getUsedStorage);
		},
		beforeDestroy() {
			this.Styles.off('created', this.getUsedStorage);
			this.Styles.off('updated', this.getUsedStorage);
			this.Styles.off('patched', this.getUsedStorage);
			this.Styles.off('removed', this.getUsedStorage);
		}
	}
</script>

<style lang="scss" scoped>
	.upgrade-link {
		color: var(--color-100);
		span {
			&:hover {
				color: var(--color-white) !important;
			}
		}
	}
</style>
